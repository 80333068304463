<script lang="ts">
  import BetweenCurlyBraces from '@components/BetweenCurlyBraces/BetweenCurlyBraces.svelte';

  import { CAREERS_FOOTER_LEGAL_CONST } from './legal-footer.const.ts';
</script>

<BetweenCurlyBraces parentPropertyName="legal:">
  <div class="flex flex-col">
    {#each CAREERS_FOOTER_LEGAL_CONST as { label, value, link }}
      <div class="flex items gap-2 leading-tight">
        <span class="text-careers-orange">{label}:</span>
        {#if link}
          <a
            class="text-sm lg:text-base font-normal text-light-cream font-source-pro"
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value},
          </a>
        {:else}
          <span class="text-sm lg:text-basefont-normal text-light-cream font-source-pro">
            {value},
          </span>
        {/if}
      </div>
    {/each}
  </div>
</BetweenCurlyBraces>
