<script lang="ts">
  import Logo from '@components/SVGs/Logo.svelte';

  import AboutFooter from './components/AboutFooter/AboutFooter.svelte';
  import LegalFooter from './components/LegalFooter/LegalFooter.svelte';
  import SitemapFooter from './components/SitemapFooter/SitemapFooter.svelte';

  const currentYear = new Date().getFullYear();
</script>

<footer class="w-full bg-black-carbon lg:fixed bottom-0 left-0 z-0 border-t border-neutral-600">
  <div
    class="careers-wrapper py-16 lg:py-120 grid grid-cols-1 lg:grid-cols-3 gap-8 md:gap-4 lg:gap-0"
  >
    <!--    Logo and Company information -->
    <div class="flex flex-col gap-8 lg:gap-16">
      <Logo css="max-w-[180px] fill-light-cream" />

      <span class="text-base font-normal text-light-cream font-source-pro">
        HUMANS INC. 2250 E Maple Ave, <br /> El Segundo, CA 90245 © {currentYear} Flip
      </span>
    </div>

    <!-- About and Legal -->
    <div class="flex flex-col gap-8 lg:gap-16">
      <AboutFooter />
      <LegalFooter />
    </div>

    <SitemapFooter />
  </div>
</footer>
