import type { FooterItem } from '../../careers-footer.modal.ts';

export const CAREERS_FOOTER_ABOUT_CONST: FooterItem[] = [
  {
    label: 'version',
    value: '2.0.23'
  },
  {
    label: 'whats new',
    value: 'careers page'
  },
  {
    label: 'up next',
    value: '// [tbc]'
  },
  {
    label: 'coming soon',
    value: 'more cool stuff'
  },
  {
    label: 'social',
    value: '@joinflip',
    link: {
      url: 'https://twitter.com/joinflip',
      title: 'twitter'
    }
  }
];
