import { urlBuilder } from '@core/utils/url-builder.ts';

import type { FooterItem } from '../../careers-footer.modal.ts';

export const CAREERS_FOOTER_SITEMAP_CONST: FooterItem[] = [
  {
    label: 'link',
    value: 'careers page',
    link: {
      url: urlBuilder('#open-positions'),
      title: 'flip.shop/careers page'
    }
  },
  {
    label: 'link',
    value: 'our team',
    link: {
      url: urlBuilder('#tech-crew'),
      title: 'flip.shop/tech-crew page'
    }
  }
];
