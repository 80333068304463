import type { FooterItem } from '../../careers-footer.modal.ts';

export const CAREERS_FOOTER_LEGAL_CONST: FooterItem[] = [
  {
    label: 'terms',
    value: 'of use',
    link: {
      url: 'https://flip.shop/terms',
      title: 'flip.shop/terms of use'
    }
  },
  {
    label: 'privacy',
    value: 'policy',
    link: {
      url: 'https://flip.shop/privacy',
      title: 'flip.shop/privacy policy'
    }
  }
];
