<script lang="ts">
  import BetweenCurlyBraces from '@components/BetweenCurlyBraces/BetweenCurlyBraces.svelte';

  import { CAREERS_FOOTER_SITEMAP_CONST } from './sitemap-footer.const.ts';
</script>

<BetweenCurlyBraces parentPropertyName="sitemap:" as="array">
  <div class="flex flex-col">
    {#each CAREERS_FOOTER_SITEMAP_CONST as { label, value, link }}
      <BetweenCurlyBraces parentPropertyName="">
        <div class="flex items gap-2 leading-tight">
          <span class="text-careers-purple">{label}:</span>
          {#if link}
            <a
              class="text-sm lg:text-base cursor-pointer font-normal text-light-cream font-source-pro"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value},
            </a>
          {:else}
            <span class="text-sm lg:text-base font-normal text-light-cream font-source-pro">
              {value},
            </span>
          {/if}
        </div>
      </BetweenCurlyBraces>
    {/each}
  </div>
</BetweenCurlyBraces>
